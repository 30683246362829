<template>
  <login-layout>
    <div v-if="successfully">
      <div class="row">
        <div class="activationPage__title">
          Your account has been successfully activated!
        </div>
      </div>
      <div class="row">
        <div class="activationPage__subTitle">
          Now you have access to your personal account on the Aura Impact
          Platform.
        </div>
      </div>
      <div v-if="step[0]">
        <div class="row">
          <div class="col-lg-6 activationPage__login">Log In</div>
        </div>
        <div class="row loginPage__mb-50">
          <div class="col-lg-4">
            <base-input
              :autocomplete="true"
              :error="v$.mail.$error"
              :error-message="v$.mail.$errors"
              v-model="mail"
              type="email"
            >
              Enter your email
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 position-relative">
            <base-input
              :autocomplete="true"
              :error="v$.password.$error"
              :error-message="v$.password.$errors"
              v-model="password"
              :maxlength="32"
              :type="passwordInputType"
            >
              Enter password
            </base-input>
            <div
              class="registrationPage__passwordIcon"
              @click="showPassword = !showPassword"
            >
              <show-password-icon />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 loginPage__forgetPassword">
            <a href="">Forgot password?</a>
          </div>
        </div>
        <div class="row">
          <base-button
            :calendar="true"
            @keyup.enter="verify()"
            @click="verify()"
            >Log In
          </base-button>
        </div>
      </div>
      <div v-if="step[1]">
        <p class="loginPage__selectRole">Select role</p>
        <p class="loginPage__selectRoleSubtitle">
          Your credentials match multiple roles in Aura Impact Platform. <br />
          Please select which role you would like to log in with.
        </p>
        <div class="loginPage__selectRoleContainer">
          <div
            v-for="role in userRoles"
            class="loginPage__role"
            :class="{ 'loginPage__role-active': selectRole === role.id }"
            @click="selectRole = role.id"
          >
            Log In as an {{ role.name }}
          </div>
        </div>
        <base-button
          class="w-max-content mt-30"
          :calendar="true"
          @click="auth(selectRole)"
        >
          Log In
        </base-button>
      </div>
    </div>
    <div v-if="wrong">
      <div class="activationPage__title">Something went wrong</div>
      <div class="activationPage__subTitle activationPage__subTitle-bold">
        Ann error occured during account activation.
      </div>
      <div class="activationPage__subTitle">
        Please, follow the link from email one more time or contact Aura Impact
        Support.
      </div>
      <div class="w-max-content">
        <a href="mailto:support@aura-impact.com">
          <base-button class="registration__btn" :book="true">
            {{ $t("sidebarContactSupportBtn") }}
          </base-button>
        </a>
      </div>
    </div>
  </login-layout>
</template>

<script>
import BaseInput from "@/components/UI/inputs/BaseInput";
import LoginLayout from "@/layouts/LoginLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { email, required } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import ShowPasswordIcon from "@/components/UI/icons/ShowPasswordIcon.vue";

export default {
  name: "ActivationPage",
  components: { ShowPasswordIcon, BaseButton, LoginLayout, BaseInput },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      showPassword: false,
      userRoles: null,
      selectRole: null,
      step: [true, false],
      successfully: false,
      wrong: false,
      mail: "",
      password: "",
      wrongMail: false,
      wrongPassword: false,
    };
  },
  validations() {
    return {
      mail: { required, email },
      password: { required },
    };
  },
  mounted() {
    this.checkRegistrationStatus();
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 13) {
        this.v$.$touch();
        if (this.v$.$error) {
          this.toast.error(this.$t("checkInputs"));
          return;
        }
        if (this.step[0]) {
          this.verify();
        }
        if (this.step[1]) {
          this.auth(this.selectRole);
        }
      }
    });
  },
  methods: {
    verify() {
      this.$noAuth
        .post("/auth/verify", {
          email: this.mail,
          password: this.password,
        })
        .then(({ data }) => {
          this.userRoles = data.body.roles;
          if (data.body.roles.length === 1) {
            this.auth(this.userRoles[0].id);
            return;
          }
          this.step[0] = false;
          this.step[1] = true;
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    auth(role) {
      this.$noAuth
        .post(
          "/auth/login",
          {
            email: this.mail,
            password: this.password,
          },
          {
            params: {
              role: role,
            },
          }
        )
        .then(({ data }) => {
          this.$store.commit("SET_CURRENT_USER_INFORMATION", data);
          localStorage.setItem("refresh_token", data.body.refresh_token);
          // this.$router.push("/");
          document.location.href = !!localStorage.getItem("no_auth_path")
            ? localStorage.getItem("no_auth_path")
            : "/";
          localStorage.removeItem("no_auth_path");
          // this.$router.push("/");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    checkRegistrationStatus() {
      this.$noAuth
        .patch("/profiles/activate", {
          code: this.$route.query.code,
        })
        .then(({}) => {
          this.successfully = true;
        })
        .catch(() => {
          this.wrong = true;
        });
    },
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
  },
};
</script>

<style></style>
