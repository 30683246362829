<template>
  <div class="loginPage" :class="{ loginPage_png_bg: !checkWebp }">
    <div class="container">
      <router-link to="/login">
        <aura-impact-logo />
      </router-link>
      <slot />
    </div>
  </div>
</template>

<script>
import AuraImpactLogo from "@/components/UI/icons/AuraImpactLogo";

export default {
  name: "LoginLayout",
  components: { AuraImpactLogo },
  beforeCreate() {
    document.title = this.$route.meta.title[this.$i18n.locale];
  },
  computed: {
    checkWebp() {
      try {
        return (
          document
            .createElement("canvas")
            .toDataURL("image/webp")
            .indexOf("data:image/webp") === 0
        );
      } catch (err) {
        return false;
      }
    },
  },
};
</script>

<style></style>
