<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
            <path d="M8.09611 5.24407C9.02709 4.86984 10.0475 4.77743 11.0306 4.97834C12.0137 5.17925 12.916 5.66462 13.6255 6.37412C14.335 7.08362 14.8204 7.98597 15.0213 8.96904C15.2222 9.9521 15.1298 10.9725 14.7556 11.9035L16.3917 13.5396C17.8333 12.3235 18.75 10.9774 18.75 10.1996C18.75 8.88629 16.4089 6.12129 13.6739 4.76629C12.5357 4.1837 11.2785 3.87135 10 3.85352C9.05239 3.86178 8.11384 4.0388 7.22833 4.37629L8.09611 5.24407Z"
                  fill="black"/>
            <path d="M18.7745 17.8101L12.4045 11.4401C12.669 11.0062 12.81 10.5083 12.8122 10.0001C12.8089 9.77468 12.7764 9.5506 12.7156 9.33347C12.5877 9.54739 12.4072 9.72497 12.1911 9.84926C11.9751 9.97356 11.7309 10.0404 11.4817 10.0435C11.0893 10.0435 10.7129 9.88783 10.4352 9.61068C10.1574 9.33354 10.0009 8.95752 10 8.56514C10.0028 8.30749 10.0737 8.05516 10.2054 7.8337C10.3371 7.61224 10.525 7.42952 10.75 7.30403C10.5066 7.23081 10.2542 7.19174 10 7.18792C9.49183 7.19016 8.99392 7.33116 8.56001 7.5957L2.19001 1.2257C2.10471 1.14058 1.98913 1.09277 1.86863 1.09277C1.74812 1.09277 1.63254 1.14058 1.54724 1.2257L1.22613 1.54736C1.18385 1.58953 1.1503 1.63962 1.12741 1.69477C1.10452 1.74992 1.09274 1.80904 1.09274 1.86875C1.09274 1.92846 1.10452 1.98759 1.12741 2.04274C1.1503 2.09788 1.18385 2.14798 1.22613 2.19014L4.75168 5.7157C2.63168 7.23792 1.25168 9.28125 1.25168 10.2001C1.25168 11.8668 5.39501 16.1446 9.96612 16.1446C11.4108 16.1158 12.8281 15.7449 14.1017 15.0624L17.8122 18.7729C17.8544 18.8152 17.9045 18.8487 17.9596 18.8716C18.0148 18.8945 18.0739 18.9063 18.1336 18.9063C18.1933 18.9063 18.2525 18.8945 18.3076 18.8716C18.3628 18.8487 18.4129 18.8152 18.455 18.7729L18.7761 18.4518C18.8608 18.3664 18.9082 18.2509 18.9079 18.1306C18.9076 18.0104 18.8596 17.8951 18.7745 17.8101ZM10 15.1251C9.044 15.1283 8.10628 14.8632 7.29337 14.3601C6.48046 13.857 5.82495 13.1359 5.40132 12.2789C4.9777 11.4218 4.80293 10.4632 4.89688 9.51179C4.99082 8.5604 5.34972 7.65443 5.93279 6.89681L7.59613 8.56014C7.33103 8.99383 7.18981 9.49185 7.18779 10.0001C7.18772 10.3695 7.26041 10.7352 7.4017 11.0765C7.54299 11.4178 7.75013 11.7278 8.01128 11.989C8.27242 12.2502 8.58246 12.4574 8.9237 12.5988C9.26493 12.7402 9.63066 12.8129 10 12.8129C10.5084 12.8107 11.0064 12.6693 11.44 12.404L13.1033 14.0674C12.2142 14.7533 11.1229 15.1252 10 15.1251Z"
                  fill="black"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "ShowPasswordIcon"
}
</script>

<style>

</style>
